import {StatusPageTheme} from '@/status_pages/types';
import React from 'react';
import {LegacyFooter} from '@/status_pages/display/components/theme-specific/legacy/LegacyFooter';
import {InspireFooter} from '@/status_pages/display/components/theme-specific/inspire/InspireFooter';

export interface StatusPageFooterProps {
  theme: StatusPageTheme;
  brandName: string;
}

const SP_MARKETING_URL =
  'https://uptime.com/status-page/?utm_campaign=status.uptime&utm_content=SP-notifications&utm_medium=powered-by&utm_source=inappuptime';

export const StatusPageFooter = ({theme, brandName}: StatusPageFooterProps) => {
  if (theme === StatusPageTheme.INSPIRE) {
    return <InspireFooter siteUrl={SP_MARKETING_URL} brandName={brandName} />;
  }
  return <LegacyFooter siteUrl={SP_MARKETING_URL} brandName={brandName} />;
};
