import React, {useState} from 'react';

import {SectionNav} from '@/jskit/react/SectionNav';
import {ComponentHistorySection} from '@/status_pages/display/components/tabs/History/subtabs/ComponentHistorySection';
import {PastIncidentsSection} from '@/status_pages/display/components/tabs/History/subtabs/PastIncidentsSection';
import {PastMaintenanceSection} from '@/status_pages/display/components/tabs/History/subtabs/PastMaintenanceSection';
import {HistoryOverviewSection} from '@/status_pages/display/components/tabs/History/subtabs/HistoryOverviewSection';
import {extractLastIncidentUpdateTime, compareIncidentsByLastUpdateFn} from '@/status_pages/display/Utils';
import {ComponentStatusValue, StatusPage} from '@/status_pages/types';
import {CurrentStatusTabs} from '@/status_pages/display/components/tabs/CurrentStatus/CurrentStatus';

export enum HistoryTabs {
  HISTORY_OVERVIEW,
  PAST_INCIDENTS,
  PAST_MAINTENANCE,
  COMPONENT_HISTORY,
}

export interface HistoryProps {
  statuspage: StatusPage;
  componentStatusRank: Record<ComponentStatusValue, number>;
  componentStatusChoices: Record<ComponentStatusValue, string>;
  historyStartDate: object;
  historyEndDate: object;
}

export const History = ({
  statuspage,
  componentStatusRank,
  componentStatusChoices,
  historyStartDate,
  historyEndDate,
}: HistoryProps) => {
  const [currentTab, setCurrentTab] = useState(HistoryTabs.HISTORY_OVERVIEW);
  const allTabs = [];
  const allIncidents = (statuspage.past_incidents || []).map((incident) =>
    Object.assign({}, incident, {lastUpdated: extractLastIncidentUpdateTime(incident)})
  );
  const pastIncidents = allIncidents
    .filter((incident) => incident.incident_type === 'INCIDENT')
    .sort(compareIncidentsByLastUpdateFn);
  const pastMaintenance = allIncidents
    .filter((incident) => incident.incident_type === 'SCHEDULED_MAINTENANCE')
    .sort(compareIncidentsByLastUpdateFn);
  if (statuspage.show_past_incidents || statuspage.show_component_history) {
    allTabs.push([HistoryTabs.HISTORY_OVERVIEW, 'History Overview']);
  }
  if (statuspage.show_past_incidents) {
    if (!statuspage.hide_empty_tabs_history || pastIncidents.length > 0) {
      allTabs.push([HistoryTabs.PAST_INCIDENTS, 'Past Incidents']);
    }
    if (!statuspage.hide_empty_tabs_history || pastMaintenance.length > 0) {
      allTabs.push([HistoryTabs.PAST_MAINTENANCE, 'Past Maintenance']);
    }
  }
  if (statuspage.show_component_history) {
    allTabs.push([HistoryTabs.COMPONENT_HISTORY, 'Component History']);
  }
  if (!allTabs.length) {
    return false;
  }

  return (
    <SectionNav
      sectionIds={allTabs}
      currentSectionId={currentTab}
      navClass="nav nav-tabs-plain nav-tabs-secondary mb-4"
      onSectionNav={setCurrentTab}
    >
      <HistoryOverviewSection
        currentSectionId={currentTab}
        statuspage={statuspage}
        incidents={allIncidents}
        componentStatusRank={componentStatusRank}
        componentStatusChoices={componentStatusChoices}
        historyStartDate={historyStartDate}
        historyEndDate={historyEndDate}
      />
      <PastIncidentsSection
        currentSectionId={currentTab}
        incidents={pastIncidents}
        componentStatusRank={componentStatusRank}
      />
      <PastMaintenanceSection
        currentSectionId={currentTab}
        incidents={pastMaintenance}
        componentStatusRank={componentStatusRank}
      />
      <ComponentHistorySection
        currentSectionId={currentTab}
        statuspage={statuspage}
        componentStatusRank={componentStatusRank}
        componentStatusChoices={componentStatusChoices}
        historyStartDate={historyStartDate}
        historyEndDate={historyEndDate}
      />
    </SectionNav>
  );
};
