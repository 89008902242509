import css from './InspireFooter.module.css';

export interface InspireFooterProps {
  siteUrl: string;
  brandName: string;
}

export const InspireFooter = ({siteUrl, brandName}: InspireFooterProps) => (
  <div className="text-center p-5">
    <a href={siteUrl} className={css.uptimeLink} target="_blank">
      {`Powered by ${brandName} Status Page`}
    </a>
  </div>
);
