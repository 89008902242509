import css from './LegacyFooter.module.css';

export interface LegacyFooterProps {
  siteUrl: string;
  brandName: string;
}

export const LegacyFooter = ({siteUrl, brandName}: LegacyFooterProps) => (
  <div className="text-muted font-14 py-4">
    <a href={siteUrl} className={css.uptimeLink} target="_blank">
      {`Powered by ${brandName} Status Page`}
    </a>
  </div>
);
